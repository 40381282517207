<template>
  <v-row id="permission-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="savePermission">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12 col-lg-6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-subheader>
                            <v-text-field  ref="name" v-model="model.name" :error-messages="$helpers.errorMsg('name', $v.model.name, 'First Name')"
                                placeholder="Name" outlined @input="$v.model.name.$touch()"  @blur="$v.model.name.$touch()" required/>
                        </v-col>
                        <v-col cols="12 col-lg-6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Display Name</v-subheader>
                            <v-text-field ref="display_name" outlined v-model="model.display_name" placeholder="Display Name"  required/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="ma-0 pt-0 pb-0 pl-0">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Description</v-subheader>
                            <v-textarea hide-details="auto" class="mb-3" auto-grow
                                rows="4" v-model="model.description" row-height="20" placeholder="Description" outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" outlined color="primary" class="mr-4" @click.native="$router.push('/permissions')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
    data: (vm) => ({
        permissionId: vm.$route.params.permissionId,
        show: false,
        model: {
            name: '',
            display_name: '',
            description: '',
        },
    }),
    validations: {
        model: {
            name: { required},
        }
    },
    created() {
        if (this.permissionId != 'create') {
          this.getSelectedPermission(this.permissionId).then(response => this.model = response);
        }
    },
    methods: {
        ...mapActions(['getSelectedPermission', 'createPermission', 'updateSelectedPermission']),

        savePermission() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            if (this.permissionId != 'create') {
                this.updateSelectedPermission(this.model).then(resp => this.$router.push("/permissions"));
            } else {
                this.createPermission(this.model).then(resp => this.$router.push("/permissions"));
            }
        },
    },
};
</script>
